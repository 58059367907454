/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "negAppRestApi",
            "endpoint": "https://iaplykl779.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:529cffd4-5680-419a-9502-49a1f19b5e4b",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_OlBhlGjfP",
    "aws_user_pools_web_client_id": "6g8rkjnmk4csqahdidufhqadrq",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ]
};


export default awsmobile;
